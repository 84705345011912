import { db } from '@/config/firebase';
import config from '@/config/env-constants'

// util
import { DateUtil } from '../utils/dateutil';

async function getAuditTrailLogs(param) {
    let parentRef = db.collection(param.collection).doc(param.id);

    let logsRef = parentRef.collection('auditTrailLogs');

    if (param.userId && param.userId.length > 0) {
        logsRef = logsRef.where("createdBy", "==", param.userId);
        logsRef = logsRef.where("dateCreated", "<=", DateUtil.getCurrentTimestamp());
    }

    logsRef = logsRef.orderBy('dateCreated', 'desc').limit(config.maxLogs);

    return logsRef.get().then(querySnapshot => {
        let auditLogs = {};
        querySnapshot.forEach(function (doc) {
            let auditLog = doc.data();
            auditLog.id = doc.id;
            auditLogs[doc.id] = auditLog;
        })
        return auditLogs;
    }).catch(error => {
        return error;
    })
}

export default {
    getAuditTrailLogs
}